import { ERROR_MESSAGE } from 'Constants';
import { auroraApi, AuroraEndpointBuilder } from 'Services/API/Aurora';
import { setToastMessage } from 'Services/StateManagement/Actions';

export const postHideBanner = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, number>({
        query: (bannerId) => ({
            url: `/v1/TermsConditions/HideBanner?bannerId=${bannerId}`,
            method: 'POST',
        }),
        async onQueryStarted(bannerId, { dispatch, queryFulfilled }) {
            const result = dispatch(
                auroraApi.util.updateQueryData(
                    'getActiveBanner',
                    undefined,
                    (draft) => {
                        if (draft.id === bannerId) {
                            draft.isHidden = true;
                        } else {
                            throw new Error(
                                'Attempted to modify non-active banner id.',
                            );
                        }
                    },
                ),
            );
            try {
                await queryFulfilled;
            } catch {
                result.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.BannerHide,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
    });

import {
    BaseQueryFn,
    createApi,
    EndpointBuilder,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { v4 as uuidv4 } from 'uuid';

import { RequestHeaders } from 'Constants';
import { acquireToken } from 'Helpers/MsalHelper';
import { msalInstance } from 'index';
import { OperationContext } from 'Models/OperationContext';
import { setErrorMessage } from 'Services/StateManagement/Actions';
import { RootState } from 'Services/StateManagement/store';
import { logError, logOperation } from 'Utils/logging';

import { createBanner } from './endpoints/adminEndpoints/createBanner';
import { deleteBanner } from './endpoints/adminEndpoints/deleteBanner';
import { getBanners } from './endpoints/adminEndpoints/getBanners';
import { putBanner } from './endpoints/adminEndpoints/putBanner';
import { setActiveBannerId } from './endpoints/adminEndpoints/setActiveBannerId';
import { deleteAllThreads } from './endpoints/deleteAllThreads';
import { deleteThread } from './endpoints/deleteThread';
import { downloadThreadAttachment } from './endpoints/downloadThreadAttachment';
import { getActiveBanner } from './endpoints/getActiveBanner';
import { getPrompts } from './endpoints/getPrompts';
import { getSpeechToken } from './endpoints/getSpeechToken';
import { getThreadDetails } from './endpoints/getThreadDetails';
import { getThreadMessages } from './endpoints/getThreadMessages';
import { getThreads } from './endpoints/getThreads';
import { getUser } from './endpoints/getUser';
import { getUserSkills } from './endpoints/getUserSkills';
import { postMessageReaction } from './endpoints/patchMessageReaction';
import { patchThread } from './endpoints/patchThread';
import { patchThreadChatHistoryLimit } from './endpoints/patchThreadChatHistoryLimit';
import { patchThreadSkills } from './endpoints/patchThreadSkills';
import { patchUser } from './endpoints/patchUser';
import { patchUserSkills } from './endpoints/patchUserSkills';
import { postAttachLocalFileToThread } from './endpoints/postAttachLocalFileToThread';
import { postAttachOneDriveFileToThread } from './endpoints/postAttachOneDriveFileToThread';
import { postErmMessage } from './endpoints/postErmMessage';
import { postErmThread } from './endpoints/postErmThread';
import { postHideBanner } from './endpoints/postHideBanner';
import { postMessage } from './endpoints/postMessage';
import { postMessageRtc } from './endpoints/postMessageRtc';
import { postShowBanner } from './endpoints/postShowBanner';
import { postThread } from './endpoints/postThread';
import { removeThreadAttachment } from './endpoints/removeThreadAttachment';

const baseUrl = process.env.REACT_APP_API_ENDPOINT!;

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, api) => {
        const { extra } = api as { extra: { context: OperationContext } };
        const accessToken = await acquireToken(
            msalInstance,
            msalInstance.getActiveAccount() ?? undefined,
            [process.env.REACT_APP_API_REQUEST_SCOPE!],
        );
        headers.set(RequestHeaders.Authorization, `Bearer ${accessToken}`);
        headers.set(RequestHeaders.ContentType, 'application/json');
        headers.set(RequestHeaders.RequestId, extra.context.id);
        headers.set(RequestHeaders.SessionId, extra.context.id);
        return headers;
    },
});

const baseQueryWithLogging: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const context: OperationContext = {
        id: uuidv4(),
        name: api.endpoint,
        startTime: new Date(),
    };
    let result = await baseQuery(
        args,
        { ...api, extra: { context } },
        extraOptions,
    );

    if (result.error) {
        const { globalState } = api.getState() as RootState;
        logError(result.error as any, context, globalState.sessionId);
        logOperation(context, globalState.sessionId);
        api.dispatch(
            setErrorMessage({
                message: 'Error processing Aurora API call',
                debugInfo: '',
                requestId: context.id,
            }),
        );
        // TODO: any global error messages for all api calls can be handled here
    }
    return result;
};

export type AuroraEndpointBuilder = EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Tag,
    ReducerPath
>;

// Cache tags
export const tags = ['Messages', 'ThreadDetails', 'Threads', 'Banners'];
export type Tag = (typeof tags)[number];

export const reducerPath = 'auroraApi';
export type ReducerPath = typeof reducerPath;

// Define a service using a base URL and expected endpoints
export const auroraApi = createApi({
    reducerPath,
    baseQuery: baseQueryWithLogging,
    tagTypes: tags,
    endpoints: (builder: AuroraEndpointBuilder) => ({
        getSpeechToken: getSpeechToken(builder),
        getThreads: getThreads(builder),
        getThreadDetails: getThreadDetails(builder),
        getThreadMessages: getThreadMessages(builder),
        getPrompts: getPrompts(builder),
        postThread: postThread(builder),
        postErmThread: postErmThread(builder),
        patchThread: patchThread(builder),
        patchThreadChatHistoryLimit: patchThreadChatHistoryLimit(builder),
        postMessageReaction: postMessageReaction(builder),
        deleteThread: deleteThread(builder),
        deleteAllThreads: deleteAllThreads(builder),
        patchThreadSkills: patchThreadSkills(builder),
        postMessage: postMessage(builder),
        postErmMessage: postErmMessage(builder),
        postMessageRtc: postMessageRtc(builder),
        postAttachLocalFileToThread: postAttachLocalFileToThread(builder),
        postAttachOneDriveFileToThread: postAttachOneDriveFileToThread(builder),
        removeThreadAttachment: removeThreadAttachment(builder),
        downloadThreadAttachment: downloadThreadAttachment(builder),
        createBanner: createBanner(builder),
        putBanner: putBanner(builder),
        getBanners: getBanners(builder),
        deleteBanner: deleteBanner(builder),
        getActiveBanner: getActiveBanner(builder),
        setActiveBannerId: setActiveBannerId(builder),
        postShowBanner: postShowBanner(builder),
        postHideBanner: postHideBanner(builder),
        getUser: getUser(builder),
        patchUser: patchUser(builder),
        getUserSkills: getUserSkills(builder),
        patchUserSkills: patchUserSkills(builder),
    }),
});

export const {
    useGetSpeechTokenQuery,
    useLazyGetSpeechTokenQuery,
    useGetThreadsQuery,
    useGetThreadDetailsQuery,
    useGetThreadMessagesQuery,
    useGetPromptsQuery,
    usePatchThreadSkillsMutation,
    usePostThreadMutation,
    usePostErmThreadMutation,
    usePostMessageMutation,
    usePostErmMessageMutation,
    usePostMessageRtcMutation,
    useDeleteThreadMutation,
    useDeleteAllThreadsMutation,
    usePatchThreadMutation,
    usePostMessageReactionMutation,
    usePatchThreadChatHistoryLimitMutation,
    usePostAttachLocalFileToThreadMutation,
    usePostAttachOneDriveFileToThreadMutation,
    useRemoveThreadAttachmentMutation,
    useDownloadThreadAttachmentQuery,
    useLazyDownloadThreadAttachmentQuery,
    useCreateBannerMutation,
    usePutBannerMutation,
    useGetBannersQuery,
    useDeleteBannerMutation,
    useGetActiveBannerQuery,
    useSetActiveBannerIdMutation,
    usePostShowBannerMutation,
    usePostHideBannerMutation,
    useGetUserQuery,
    usePatchUserMutation,
    useGetUserSkillsQuery,
    usePatchUserSkillsMutation,
} = auroraApi;

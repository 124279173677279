import { makeStyles, shorthands, Button } from '@fluentui/react-components';
import { Compose28Regular } from '@fluentui/react-icons';
import { QueryStatus } from '@reduxjs/toolkit/query';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { EnableMicrophoneInstructions } from 'Components/EnableMicrophoneInstructions';
import { ScreenPopover } from 'Components/ScreenPopover';
import { ErmLinksRecipe } from 'Containers/ErmLinksRecipe';
import { ErmLinksRegular } from 'Containers/ErmLinksRegular';
import { ErmMessageCard } from 'Containers/ErmMessageCard';
import { useAppSelector } from 'Hooks/useAppSelector';
import { useSpeechRecognitionHook } from 'Hooks/useSpeechRecognitionHook';
import { ErmChatResponseApiResource } from 'Models/ChatThread';
import {
    useGetThreadDetailsQuery,
    usePostErmMessageMutation,
    usePostErmThreadMutation,
} from 'Services/API/Aurora';
import { PostErmMessageRequest } from 'Services/API/Aurora/dto';
import {
    disableChat,
    enableChat,
    toggleErmLoading,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

const chatContainerStyles = makeStyles({
    chatContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    header: {
        ...shorthands.gridArea('header'),
        alignItems: 'center',
    },
    chatArea: {
        ...shorthands.gridArea('chatArea'),
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifySelf: 'center',
        overflowY: 'auto',
        maxHeight: '100%',
        minWidth: '80%',
    },
    floatingButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        ...shorthands.padding('10px'),
        paddingRight: '20px',
        paddingLeft: '20px',
        fontSize: '16px',
    },
});

export const ErmContainer: React.FC = () => {
    //Hooks for using Erm API's
    const [postErmThread] = usePostErmThreadMutation();

    const [
        postMessage,
        { isLoading: isPostingMessage, status: postMessageStatus },
    ] = usePostErmMessageMutation();

    const [userMessage, setUserMessage] = useState<PostErmMessageRequest>({
        threadId: 0,
        message: '',
    });
    const [messageResponse, setMessageResponse] =
        useState<ErmChatResponseApiResource>();

    // Create new thread on first load
    useEffect(() => {
        postErmThread();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Initialize speech recognition from Microsoft
    const {
        record,
        transcript,
        resetTranscript,
        isMicrophonePermissionPopupVisible,
        setIsMicrophonePermissionPopupVisible,
        toggleRecording,
    } = useSpeechRecognitionHook();

    const [isNewMessage, setIsNewMessage] = useState<boolean>(true);
    const [newChatAction, setNewChatAction] = useState<string>('');
    const [buttonHoldTimeout, setButtonHoldTimeout] =
        useState<NodeJS.Timeout | null>(null);
    const handleMouseDown = () => {
        setNewChatAction('setTextareaActive');

        const timeout = setTimeout(() => {
            setNewChatAction('setRecordingActive');
        }, 500);
        setButtonHoldTimeout(timeout);
    };

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    //Need to add this to make the chat scroll to the top when a new message is posted
    const handleScrollNewMessage = useCallback(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.clientHeight -
                scrollContainerRef.current.scrollHeight;
        }
    }, [scrollContainerRef]);
    useEffect(() => {
        if (postMessageStatus === QueryStatus.fulfilled) {
            handleScrollNewMessage();
        }
    }, [postMessageStatus, handleScrollNewMessage, scrollContainerRef]);

    const handleMouseUp = () => {
        if (buttonHoldTimeout) {
            clearTimeout(buttonHoldTimeout);
            setButtonHoldTimeout(null);
        }

        setTimeout(() => {
            setNewChatAction('');
        }, 0);
    };

    const [isChatInProgress, setIsChatInProgress] = useState<boolean>(false);
    const [chatMessage, setChatMessage] = useState<string>('');
    const toggleResetTranscript = () => {
        resetTranscript();
    };
    const setErmLoading = (isLoading: boolean) => {
        updateStore(toggleErmLoading(isLoading));
    };
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const threadId = useAppSelector((store) => store.thread.selectedThreadId);

    const { data: selectedThread, isFetching: isFetchingDetails } =
        useGetThreadDetailsQuery(threadId ?? 0, {
            skip: !threadId,
        });

    const styles = chatContainerStyles();

    useEffect(() => {
        if (isPostingMessage || isFetchingDetails) {
            updateStore(disableChat());
            return;
        }
        updateStore(enableChat());
    }, [isPostingMessage, isFetchingDetails]);

    useEffect(() => {
        setErmLoading(isPostingMessage);
    }, [isPostingMessage]);

    //Adding this to make page size dynamic
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showErmLinks = () => {
        if (!messageResponse || isNewMessage) {
            return <></>;
        }
        return messageResponse.isRecipe ? (
            <ErmLinksRecipe
                chatResponse={messageResponse}
                userMessage={userMessage}
            />
        ) : (
            <ErmLinksRegular
                chatResponse={messageResponse}
                userMessage={userMessage}
            />
        );
    };

    const sendChatMessage = async (message?: string) => {
        setIsChatInProgress(true);
        setChatMessage('');
        setUserMessage({
            message: message ?? chatMessage.trim(),
            threadId: selectedThread?.id ?? 0,
        });

        try {
            const result = await postMessage({
                threadId: threadId ?? 0,
                message: message ?? chatMessage.trim(),
            });

            if ('data' in result && result.data.data) {
                setMessageResponse(result.data.data);
            } else {
                console.error(
                    'Unexpected result from awaited postMessage',
                    result,
                );
            }
        } finally {
            setIsChatInProgress(false);
            setIsNewMessage(false);
        }
    };

    const [isDragPopoverOpen, setIsDragPopoverOpen] = useState(false);
    // Need this to prevent a flickering bug (see https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element)

    return (
        <div
            className={styles.chatContainer}
            style={{
                height: windowHeight,
                background: 'var(--colorNeutralBackground5)',
            }}
        >
            <ScreenPopover content={<></>} isOpen={isDragPopoverOpen} />

            <div className={styles.header}></div>
            <div
                ref={scrollContainerRef}
                className={styles.chatArea + ' Erm-custom-scrollbar'}
                style={{
                    alignItems: 'center',
                    width: windowWidth,
                }}
            >
                <>
                    {showErmLinks()}
                    <ErmMessageCard
                        userMessage={userMessage}
                        toggleRecording={toggleRecording}
                        isRecording={record}
                        resetTranscript={toggleResetTranscript}
                        transcript={transcript}
                        isSendMessageButtonDisabled={
                            isChatInProgress || chatMessage.trim().length === 0
                        }
                        onSendMessageButtonClick={async (message) => {
                            await sendChatMessage(message);
                        }}
                        onChange={(value) => setChatMessage(value)}
                        isNewMessage={isNewMessage}
                        newChatAction={newChatAction}
                    />
                </>
            </div>
            {isMicrophonePermissionPopupVisible && (
                <EnableMicrophoneInstructions
                    onPopupClosed={() => {
                        setIsMicrophonePermissionPopupVisible(false);
                    }}
                />
            )}
            {!isNewMessage && (
                <Button
                    className={styles.floatingButton}
                    onClick={() => setIsNewMessage(true)}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    icon={<Compose28Regular />}
                    style={{ borderRadius: '20px' }}
                >
                    New Message
                </Button>
            )}
        </div>
    );
};

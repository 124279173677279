import { createSlice } from '@reduxjs/toolkit';

import { UserInterface } from 'Models/UserInterface';

const isMobileView = window.innerWidth <= 900;
const initialState: UserInterface = {
    documentSearchOpen: false,
    rightPanelOpen: !isMobileView,
    leftPanelOpen: !isMobileView,
    settingsDialogOpen: false,
    termsPopover: false,
    ErmLoading: false,
    isChatDisabled: false,
    isLeftPanelLoaded: false,
    isChatHistoryLimitLoaded: false,
    isChatPanelLoaded: false,
    isSkillCardsLoaded: true,
    isMessagesLoading: false,
};

export const UserInterfaceSlice = createSlice({
    name: 'userInterface',
    initialState: initialState,
    reducers: {
        openDocumentSearch: (state) => {
            state.documentSearchOpen = true;
        },
        closeDocumentSearch: (state) => {
            state.documentSearchOpen = false;
        },
        toggleRightOpen: (state) => {
            state.rightPanelOpen = !state.rightPanelOpen;
        },
        toggleLeftOpen: (state) => {
            state.leftPanelOpen = !state.leftPanelOpen;
        },
        updateSettingsDialogOpen: (state) => {
            state.settingsDialogOpen = !state.settingsDialogOpen;
        },
        updateTermsPopoverTrue: (state) => {
            state.termsPopover = true;
        },
        updateTermsPopoverFalse: (state) => {
            state.termsPopover = false;
        },
        toggleErmLoading: (state, action) => {
            state.ErmLoading = action.payload;
        },
        disableChat: (state) => {
            state.isChatDisabled = true;
        },
        enableChat: (state) => {
            state.isChatDisabled = false;
        },
        setLeftPanelLoaded: (state, action) => {
            state.isLeftPanelLoaded = action.payload;
        },
        setChatHistoryLimitLoaded: (state, action) => {
            state.isChatHistoryLimitLoaded = action.payload;
        },
        setChatPanelLoaded: (state, action) => {
            state.isChatPanelLoaded = action.payload;
        },
        setSkillCardsLoaded: (state, action) => {
            state.isSkillCardsLoaded = action.payload;
        },
        setMessagesLoading: (state, action) => {
            state.isMessagesLoading = action.payload;
        },
    },
});

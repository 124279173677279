import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    makeStyles,
    Body1,
    Link,
    Button,
    shorthands,
    Card,
    CardHeader,
    Popover,
    PopoverSurface,
    PopoverTrigger,
} from '@fluentui/react-components';
import {
    ThumbLikeRegular,
    ThumbDislikeRegular,
    ThumbLikeFilled,
    ThumbDislikeFilled,
    WarningFilled,
} from '@fluentui/react-icons';
import { useCallback, useEffect, useState } from 'react';

import { ImageDialog } from 'Components/ImageDialog';
import { useLogging } from 'Hooks/useLogging';
import {
    ChatResponseReferenceApiResource,
    MessageReactionType,
} from 'Models/ChatThread';
import { EventTypes } from 'Models/EventTypes';
import {
    useLazyDownloadThreadAttachmentQuery,
    usePostMessageReactionMutation,
} from 'Services/API/Aurora';
import { downloadFileFromUrl, getFileIcon, isImageFile } from 'Utils/index';

const useStyles = makeStyles({
    card: {
        ...shorthands.margin('auto'),
        backgroundColor: 'transparent',
    },
    CardPreview: {
        paddingLeft: '60px',
        paddingRight: '60px',
        paddingBottom: '40px',
    },
    header2: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    refHeader: {
        ...shorthands.margin('auto'),
        display: 'flex',
        alignItems: 'center',
    },

    paragraph: {
        fontSize: '12px',
        marginRight: '10px',
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingBottom: '5px',
        paddingTop: '5px',
    },
});

type Props = {
    threadId: number;
    messageId: number;
    references: ChatResponseReferenceApiResource[];
    thumbUp?: boolean;
    thumbDown?: boolean;
};

export const ChatCardReferences: React.FC<Props> = ({
    threadId,
    messageId,
    references,
    thumbUp,
    thumbDown,
}) => {
    const { logEvent } = useLogging();
    const styles = useStyles();
    const [postMessageReaction, { isLoading, isError: isPostReactionError }] =
        usePostMessageReactionMutation();

    const [fetchAttachment] = useLazyDownloadThreadAttachmentQuery();

    // State to control the visibility of the popover
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // State to control the ImageDialog
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogImageUrl, setDialogImageUrl] = useState<string | null>(null);
    const [dialogImageName, setDialogImageName] = useState<string | null>(null);

    const handleThumbUpOrThumbDownClick = useCallback(
        async (reaction: MessageReactionType) => {
            try {
                postMessageReaction({
                    threadId,
                    messageId,
                    reaction,
                });
            } catch (error: any) {
                console.error('error while setting message reaction', error);
            }
        },
        [messageId, postMessageReaction, threadId],
    );

    const handleLinkClick = useCallback(
        async (item: ChatResponseReferenceApiResource) => {
            logEvent(EventTypes.REFERENCE_LINK_CLICKED);
            let url;
            if (item.url && item.url.startsWith('http')) {
                url = item.url;
            } else {
                const response = await fetchAttachment({
                    threadId: threadId ?? 0,
                    attachmentId: item.attachmentId ?? 0,
                    isResponseSupplement: item.type === 'ResponseSupplement',
                });
                url = response.data;
            }

            if (!url) {
                return;
            }

            if (isImageFile(item.name)) {
                setDialogImageUrl(url);
                setDialogImageName(item.name);
                setIsDialogOpen(true);
                return;
            }

            downloadFileFromUrl(url, item.name);
        },
        [fetchAttachment, logEvent, threadId],
    );

    const accordionHeader =
        references.length > 0 ? (
            <AccordionHeader
                style={{ marginLeft: windowWidth < 900 ? '0' : '35px' }}
            >
                References
            </AccordionHeader>
        ) : (
            <div style={{ marginLeft: '35px' }}></div>
        );

    const renderReferences = useCallback(() => {
        return references.map((item, index) => (
            <div
                key={index}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <header>
                    <Body1
                        style={{
                            marginRight: '10px',
                            padding: '5px 10px',
                            border: '2px solid var(--colorNeutralBackground3Hover)',
                            fontSize: '12px',
                        }}
                    >
                        {item.number}
                    </Body1>
                </header>
                <CardHeader
                    className={styles.header2}
                    image={
                        <img
                            src={getFileIcon(item.name, item.url)}
                            alt="File icon"
                            style={{ width: '24px', height: '24px' }}
                        />
                    }
                    header={
                        <Link
                            onClick={() => handleLinkClick(item)}
                            style={{
                                color: 'var(--colorStrokeFocus2)',
                            }}
                            target="_blank"
                        >
                            <b>{item.name}</b>
                        </Link>
                    }
                />
            </div>
        ));
    }, [handleLinkClick, references, styles.header2]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleThumbDownClick = useCallback(
        async () => await handleThumbUpOrThumbDownClick('ThumbDown'),
        [handleThumbUpOrThumbDownClick],
    );
    const handleThumbUpClick = useCallback(
        async () => await handleThumbUpOrThumbDownClick('ThumbUp'),
        [handleThumbUpOrThumbDownClick],
    );

    return (
        <div>
            <Accordion
                collapsible
                onClick={() => logEvent(EventTypes.REFERENCES_OPEN_CLICKED)}
            >
                <AccordionItem value="1">
                    <div
                        className={styles.refHeader}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {accordionHeader}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight:
                                    windowWidth < 900 ? '10px' : '50px',
                            }}
                        >
                            {windowWidth < 1050 ? (
                                <Popover open={isInfoOpen}>
                                    <PopoverTrigger>
                                        <Button
                                            appearance="transparent"
                                            icon={<WarningFilled />}
                                            style={{
                                                marginRight: '10px',
                                            }}
                                            onMouseEnter={() =>
                                                setIsInfoOpen(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsInfoOpen(false)
                                            }
                                            data-testid="message-error-warning-icon"
                                        />
                                    </PopoverTrigger>
                                    <PopoverSurface
                                        tabIndex={-1}
                                        style={{
                                            backgroundColor:
                                                'var(--colorNeutralBackground1)',
                                            fontSize: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        AI-generated responses may be incorrect
                                    </PopoverSurface>
                                </Popover>
                            ) : (
                                <p
                                    className={styles.paragraph}
                                    style={{
                                        borderRadius: '8%',
                                        backgroundColor:
                                            'var(--colorNeutralBackground2)',
                                    }}
                                >
                                    AI-generated responses may be incorrect
                                </p>
                            )}

                            <Button
                                appearance="transparent"
                                icon={
                                    thumbUp ? (
                                        <ThumbLikeFilled
                                            style={{
                                                color: 'var(--colorBrandForeground1)',
                                            }}
                                        />
                                    ) : (
                                        <ThumbLikeRegular />
                                    )
                                }
                                aria-label="Like"
                                onClick={handleThumbUpClick}
                                disabled={isLoading}
                                data-testid="thumbs-up-button"
                            />
                            <Button
                                appearance="transparent"
                                icon={
                                    thumbDown ? (
                                        <ThumbDislikeFilled
                                            style={{
                                                color: 'var(--colorBrandForeground1)',
                                            }}
                                        />
                                    ) : (
                                        <ThumbDislikeRegular />
                                    )
                                }
                                aria-label="Dislike"
                                onClick={handleThumbDownClick}
                                disabled={isLoading}
                                data-testid="thumbs-down-button"
                            />
                        </div>
                    </div>

                    <AccordionPanel>
                        <Card
                            className={styles.card}
                            style={{
                                border: '2px solid var(--colorNeutralBackground2Hover)',
                                width: windowWidth < 900 ? '100%' : '90%',
                            }}
                        >
                            {references.length > 0 && renderReferences()}
                        </Card>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <ImageDialog
                isOpen={isDialogOpen}
                imageUrl={dialogImageUrl}
                imageName={dialogImageName}
                onClose={() => setIsDialogOpen(false)}
            />
        </div>
    );
};

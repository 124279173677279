import {
    Button,
    Switch,
    Text,
    Card,
    Divider,
    makeStyles,
    shorthands,
    tokens,
    Tooltip,
    mergeClasses,
    ProgressBar,
} from '@fluentui/react-components';
import {
    Sparkle20Regular,
    CircleSmallFilled,
    ChevronRightFilled,
    ChevronDownFilled,
    ChevronDownRegular,
    ChevronRightRegular,
} from '@fluentui/react-icons';
import React, { useCallback } from 'react';

import { ChildSkill } from 'Components/ChildSkill';
import { Skill } from 'Models/Skill';

type Props = {
    skillData: Skill;
    showSwitch: boolean;
    handleSkillSelection: (
        id: number,
        skillName: string,
        isChild: boolean,
    ) => void;
    cardWidth: string;
    cardHeight?: string;
    isUpdating?: boolean;
    stableId: string;
};

const useStyles = makeStyles({
    card: {
        paddingBottom: '40px',
        height: 'min-content',
        ...shorthands.padding('10px', '10px', '10px', '10px'),
    },
    divider: {
        ...shorthands.margin('15px', '0', '10px', '0'),
    },
    settingsButton: {
        ...shorthands.padding('0'),
    },
    backgroundColorSidebar: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    backgroundColorMenu: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
});

//Returns true if the skill has child skills
const hasChildSkills = (skill: Skill): boolean => {
    return skill.childSkills.length > 0;
};

export const SkillCard: React.FC<Props> = (props: Props) => {
    //State for childSKill menu visibility
    const [childSkillsVisible, setChildSkillsVisible] = React.useState(false);
    const [showProgressBar, setShowProgressBar] = React.useState(false);
    const { cardWidth, cardHeight } = props;
    const styles = useStyles();

    const childSkillsCount = props.skillData.childSkills.length;
    const selectedChildSkillsCount = props.skillData.childSkills.reduce(
        (prev, curr) => prev + (curr.isSelected ? 1 : 0),
        0,
    );

    React.useEffect(() => {
        if (!props.isUpdating) {
            setShowProgressBar(false);
        }
    }, [props.isUpdating]);

    const cardClassName = mergeClasses(
        styles.card,
        props.showSwitch
            ? styles.backgroundColorSidebar
            : styles.backgroundColorMenu,
    );
    //Sorted child skills array
    const sortedChildren = [...props.skillData.childSkills].sort(
        (a, b) => a.orderBy - b.orderBy,
    );

    const handleSkillSwitchChange = useCallback(() => {
        setShowProgressBar(true);
        props.handleSkillSelection(
            props.skillData.id,
            props.skillData.name,
            false,
        );
    }, [props]);

    const handleSettingsClick = useCallback(
        () => setChildSkillsVisible(!childSkillsVisible),
        [childSkillsVisible],
    );
    return (
        <Card
            className={cardClassName}
            style={{ width: cardWidth }}
            data-testid={`${props.stableId}-card`}
        >
            <ProgressBar
                value={showProgressBar ? undefined : 1}
                thickness="large"
                style={{
                    borderRadius: '4px',
                    marginTop: '1px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: showProgressBar ? 'block' : 'none',
                }}
            />
            <div className="SkillCard">
                <div className="CardHeader">
                    <div className="CardTitle">
                        <Text weight="bold">{props.skillData.name}</Text>
                        {props.skillData.isSpecial && (
                            <Tooltip
                                content="Special Skill"
                                relationship="label"
                            >
                                <Sparkle20Regular
                                    style={{
                                        minWidth: '30px',
                                        maxWidth: '30px',
                                        minHeight: '25px',
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                    {props.showSwitch && (
                        <Switch
                            checked={props.skillData.isSelected}
                            name={props.skillData.name + '-switch'}
                            onChange={handleSkillSwitchChange}
                            data-testid={`${props.stableId}-switch`}
                        ></Switch>
                    )}
                </div>
                <div className="CardBody">
                    <Text align="start">{props.skillData.description}</Text>
                </div>
                <div className="CardFooter">
                    {hasChildSkills(props.skillData) && (
                        <Divider className={styles.divider}></Divider>
                    )}
                    {hasChildSkills(props.skillData) && (
                        <Button
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                            }}
                            appearance="transparent"
                            onClick={handleSettingsClick}
                        >
                            <div
                                style={{
                                    width: '25%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    zIndex: 2,
                                }}
                            >
                                {/* <Tooltip
                                    content={'New subskills available'}
                                    relationship={'label'}
                                >
                                    <CircleSmallFilled
                                        fontSize={tokens.fontSizeBase600}
                                        color={
                                            tokens.colorStatusSuccessForeground1
                                        }
                                    />
                                </Tooltip> */}
                            </div>
                            <div
                                style={{
                                    width: '33%',
                                    display: 'flex',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '50%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {childSkillsVisible ? (
                                            <ChevronDownFilled />
                                        ) : (
                                            <ChevronRightFilled />
                                        )}
                                        <div style={{ width: '4px' }}></div>
                                        Settings
                                        <div style={{ width: '18px' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '25%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                {props.skillData.isSelected && (
                                    <>
                                        <div style={{ width: '4px' }}></div>
                                        <div
                                            style={{
                                                fontWeight:
                                                    tokens.fontWeightRegular,
                                                fontSize:
                                                    tokens.fontSizeBase200,
                                                marginLeft: '-4px',
                                                justifySelf: 'flex-end',
                                            }}
                                        >
                                            ({selectedChildSkillsCount}/
                                            {childSkillsCount})
                                        </div>
                                    </>
                                )}
                            </div>
                        </Button>
                    )}
                    {childSkillsVisible &&
                        sortedChildren.map((childSkill) => (
                            <ChildSkill
                                key={childSkill.id}
                                skillData={childSkill}
                                handleChildSkillSelection={
                                    props.handleSkillSelection
                                }
                            />
                        ))}
                </div>
            </div>
        </Card>
    );
};

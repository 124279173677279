export enum EventTypes {
    NEW_CHAT_BUTTON_CLICKED = 'NewChatButtonClicked',
    USER_PROFILE_ICON_CLICKED = 'UserProfileIconClicked',
    LEFT_SIDEBAR_OPEN_CLICKED = 'LeftSidebarOpenClicked',
    LEFT_SIDEBAR_CLOSE_CLICKED = 'LeftSidebarCloseClicked',
    RIGHT_SIDEBAR_OPEN_CLICKED = 'RightSidebarOpenClicked',
    RIGHT_SIDEBAR_CLOSE_CLICKED = 'RightSidebarCloseClicked',
    SETTINGS_CLICKED = 'SettingsClicked',
    CONVERSATION_CONTEXT_CLICKED = 'ConversationContextClicked',
    REFERENCES_OPEN_CLICKED = 'ReferencesOpenClicked',
    REFERENCE_LINK_CLICKED = 'ReferenceLinkClicked',
    TOOLBAR_SKILLS_ICON_CLICKED = 'ToolbarSkillsIconClicked',
    TOOLBAR_ATTACH_ICON_CLICKED = 'ToolbarAttachIconClicked',
    TOOLBAR_MIC_ICON_CLICKED = 'ToolbarMicIconClicked',
}

import { combineReducers } from 'redux';

import { auroraApi } from 'Services/API/Aurora';
import { ErrorMessageSlice } from 'Services/StateManagement/Slices/ErrorMessageSlice';
import { GlobalStateSlice } from 'Services/StateManagement/Slices/GlobalStateSlice';
import { TermsSlice } from 'Services/StateManagement/Slices/TermsSlice';
import { ThreadSlice } from 'Services/StateManagement/Slices/ThreadSlice';
import { ToastMessageSlice } from 'Services/StateManagement/Slices/ToastMessageSlice';
import { UserInterfaceSlice } from 'Services/StateManagement/Slices/UserInterfaceSlice';
import { UserProfileSlice } from 'Services/StateManagement/Slices/UserProfileSlice';
import { UserSkillSlice } from 'Services/StateManagement/Slices/UserSkillSlice';
import { UserSlice } from 'Services/StateManagement/Slices/UserSlice';

export const reducer = combineReducers({
    errorMessage: ErrorMessageSlice.reducer,
    toastMessage: ToastMessageSlice.reducer,
    globalState: GlobalStateSlice.reducer,
    terms: TermsSlice.reducer,
    userSkills: UserSkillSlice.reducer,
    userProfile: UserProfileSlice.reducer,
    userInterface: UserInterfaceSlice.reducer,
    user: UserSlice.reducer,
    [auroraApi.reducerPath]: auroraApi.reducer,
    thread: ThreadSlice.reducer,
});

import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import React, { useCallback } from 'react';

import 'Styles/Header.Module.css';

import { MarkdownViewer } from 'Components/MarkdownViewer';
import {
    useGetActiveBannerQuery,
    usePostHideBannerMutation,
} from 'Services/API/Aurora';

const useStyles = makeStyles({
    leftButtons: {
        ...shorthands.margin('20px'),
    },
    innerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '98%',
        marginLeft: '20px',
    },
    dismissButton: {
        marginRight: '15px',
    },
});

export const BannerContainer: React.FC = () => {
    const styles = useStyles();

    // Fetch banner when the component mounts

    const { data: banner } = useGetActiveBannerQuery();
    const [postHideBanner] = usePostHideBannerMutation();

    // Temporarily dismiss the banner
    const updateBanner = useCallback(() => {
        if (banner?.id) {
            postHideBanner(banner.id);
        }
    }, [banner, postHideBanner]);

    // If no banner was returned from API, return null
    if (!banner || banner.isHidden) {
        return null;
    }
    return (
        <div
            style={{
                backgroundColor: 'var(--colorNeutralBackground6)',
                width: '100%',
                padding: '10px 0',
            }}
            data-testid="banner-component"
        >
            <div className={styles.innerHeader}>
                <div className="messageContainer">
                    <MarkdownViewer content={banner.message} />
                </div>

                <Button
                    appearance="subtle"
                    className={styles.dismissButton}
                    icon={<DismissRegular />}
                    onClick={updateBanner}
                    data-testid="dismiss-banner-button"
                />
            </div>
        </div>
    );
};

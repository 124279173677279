import { ErrorMessageSlice } from 'Services/StateManagement/Slices/ErrorMessageSlice';
import { GlobalStateSlice } from 'Services/StateManagement/Slices/GlobalStateSlice';
import { TermsSlice } from 'Services/StateManagement/Slices/TermsSlice';
import { ThreadSlice } from 'Services/StateManagement/Slices/ThreadSlice';
import { ToastMessageSlice } from 'Services/StateManagement/Slices/ToastMessageSlice';
import { UserInterfaceSlice } from 'Services/StateManagement/Slices/UserInterfaceSlice';
import { UserProfileSlice } from 'Services/StateManagement/Slices/UserProfileSlice';
import { UserSkillSlice } from 'Services/StateManagement/Slices/UserSkillSlice';
import { UserSlice } from 'Services/StateManagement/Slices/UserSlice';

export const { updateUserSkills } = UserSkillSlice.actions;
export const { updateTerms } = TermsSlice.actions;
export const { updateUserProfileAction, updateUserProfilePictureAction } =
    UserProfileSlice.actions;

export const {
    openDocumentSearch,
    closeDocumentSearch,
    toggleRightOpen,
    toggleLeftOpen,
    updateSettingsDialogOpen,
    updateTermsPopoverTrue,
    updateTermsPopoverFalse,
    toggleErmLoading,
    disableChat,
    enableChat,
    setLeftPanelLoaded,
    setChatHistoryLimitLoaded,
    setSkillCardsLoaded,
    setChatPanelLoaded,
    setMessagesLoading,
} = UserInterfaceSlice.actions;

export const { updateUserAction, toggleThemeAction } = UserSlice.actions;

export const { setIsHostedInTeamsAction, setAccessToken } =
    GlobalStateSlice.actions;

export const { setErrorMessage, clearErrorMessage } = ErrorMessageSlice.actions;

export const { setToastMessage, clearToastMessage } = ToastMessageSlice.actions;

export const {
    setSelectedThreadId,
    unselectThreadId,
    addLocalFileToThread,
    removeLocalFileFromThread,
    initializeThreadPagination,
    incrementThreadPage,
    setIsRetryingTrue,
    setIsRetryingFalse,
    setIsCreatingNewThread,
    setIsNewThread,
    setIsFetchingMessages,
    setCanFetchMoreMessages,
    setIsPostingMessage,
} = ThreadSlice.actions;
